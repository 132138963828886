body {
  font-family: 'Lato', sans-serif;
}

.react-datepicker-wrapper input {
  border-width: 0;
}

.rc-menu {
  outline: none;
  margin-bottom: 0;
  border: 0;
  padding-left: 0;
  list-style: none;
  box-shadow: 0 0 4px #353535;
  border-radius: 0 3px 3px 0;
  color: #eee;
  background-color: #263238;
}
.rc-menu-hidden {
  display: none;
}
.rc-menu-collapse {
  overflow: hidden;
  transition: height 0.3s ease-out;
}
.rc-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.rc-menu-item-group-title {
  color: #999;
  line-height: 1.5;
  padding: 8px 10px;
  border-bottom: 1px solid #dedede;
}
.rc-menu-item-active,
.rc-menu-submenu-active > .rc-menu-submenu-title {
  background-color: #677d88;
}
.rc-menu-item-selected {
  background-color: #677d88;
  transform: translateZ(0);
}
.rc-menu-submenu-selected {
  background-color: #263238;
}
.rc-menu > li.rc-menu-submenu {
  padding: 0;
}
.rc-menu-horizontal.rc-menu-sub,
.rc-menu-vertical.rc-menu-sub,
.rc-menu-vertical-left.rc-menu-sub,
.rc-menu-vertical-right.rc-menu-sub {
  min-width: 160px;
  margin-top: 0;
}
.rc-menu-item,
.rc-menu-submenu-title {
  margin: 0;
  position: relative;
  display: block;
  padding: 7px 7px 7px 16px;
  white-space: nowrap;
}
.rc-menu > .rc-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  padding: 0;
  line-height: 0;
  background-color: #263238;
}
.rc-menu-submenu-popup {
  position: absolute;
}
.rc-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.rc-menu-submenu > .rc-menu {
  background-color: #263238;
}
.rc-menu .rc-menu-submenu-title .anticon,
.rc-menu .rc-menu-item .anticon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  top: -1px;
}

.rc-menu-inline {
  padding: 0;
}

.rc-menu-inline > .rc-menu-item,
.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding: 16px 8px 16px 24px;
}

.rc-menu-inline .rc-menu-submenu-arrow {
  display: inline-block;
  font: normal normal normal 14px FontAwesome;
  font-size: inherit;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: 16px;
  line-height: 1.5em;
}

.rc-menu-inline .rc-menu-submenu-arrow:before {
  content: '\f0da';
}
.rc-menu-inline .rc-menu-submenu-arrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
.rc-menu-inline .rc-menu-submenu-open > .rc-menu-submenu-title .rc-menu-submenu-arrow {
  transform: rotate(90deg);
}
.rc-menu-sub.rc-menu-inline {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.rc-menu-sub.rc-menu-inline > .rc-menu-item,
.rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 0;
}
